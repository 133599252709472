import revive_payload_client_2XIogNDk2G from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/nuxt@3.13.2_@azure+cosmos@4.2.0_@azure+storage-blob@12.26.0_@parcel+watcher@2.4.1_@types+node_v3s32t47cdhzzvnrdiyetky7pi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_g1L03FiiLH from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/nuxt@3.13.2_@azure+cosmos@4.2.0_@azure+storage-blob@12.26.0_@parcel+watcher@2.4.1_@types+node_v3s32t47cdhzzvnrdiyetky7pi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_TZdSlX6ySW from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/nuxt@3.13.2_@azure+cosmos@4.2.0_@azure+storage-blob@12.26.0_@parcel+watcher@2.4.1_@types+node_v3s32t47cdhzzvnrdiyetky7pi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_wAEPFY9SpE from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/nuxt@3.13.2_@azure+cosmos@4.2.0_@azure+storage-blob@12.26.0_@parcel+watcher@2.4.1_@types+node_v3s32t47cdhzzvnrdiyetky7pi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ULsxrkOfcv from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/nuxt@3.13.2_@azure+cosmos@4.2.0_@azure+storage-blob@12.26.0_@parcel+watcher@2.4.1_@types+node_v3s32t47cdhzzvnrdiyetky7pi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_afiTcdwAnF from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/nuxt@3.13.2_@azure+cosmos@4.2.0_@azure+storage-blob@12.26.0_@parcel+watcher@2.4.1_@types+node_v3s32t47cdhzzvnrdiyetky7pi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_OBzwLp0BCn from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/nuxt@3.13.2_@azure+cosmos@4.2.0_@azure+storage-blob@12.26.0_@parcel+watcher@2.4.1_@types+node_v3s32t47cdhzzvnrdiyetky7pi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/signalznow/signalznow/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_pdjnCvlmDB from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/nuxt@3.13.2_@azure+cosmos@4.2.0_@azure+storage-blob@12.26.0_@parcel+watcher@2.4.1_@types+node_v3s32t47cdhzzvnrdiyetky7pi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/home/runner/work/signalznow/signalznow/frontend/.nuxt/pwa-icons-plugin.ts";
import pwa_client_ZUfkpjEMoJ from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_magicast@0.3.5_rollup@4.24.4_vite@6.0.7_@types+node@22.10.5_jiti@2.4.2__j5b5npyk23dhze7ekp4m3qbpne/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_t0BBAUc628 from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/@hebilicious+vue-query-nuxt@0.3.0_patch_hash=5dcq766eeuesmqdcvoqrnf6tzi_@tanstack+vue-query@5_btwzqvpvwrk27djmlmyd7rkqzi/node_modules/@hebilicious/vue-query-nuxt/dist/runtime/plugin.mjs";
import plugin_1F6fXCq7hD from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/@sidebase+nuxt-auth@0.10.0_@azure+cosmos@4.2.0_@azure+storage-blob@12.26.0_encoding@0.1.13_ma_pooi2lqa6bglmcyuq2eumdv5t4/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import plugin_52sLyo1tNa from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.13.2_@azure+cosmos@4.2.0_@azure+storag_2znaii6o4gdskru2ubadxhwaxi/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import error_handler_kEP5FliEXj from "/home/runner/work/signalznow/signalznow/frontend/plugins/error-handler.ts";
import observability_vJGwVPmCRv from "/home/runner/work/signalznow/signalznow/frontend/plugins/observability.ts";
import timeago_wa2DbggHAx from "/home/runner/work/signalznow/signalznow/frontend/plugins/timeago.ts";
import toastification_xLadjaQwvu from "/home/runner/work/signalznow/signalznow/frontend/plugins/toastification.ts";
import vuetify_7h9QAQEssH from "/home/runner/work/signalznow/signalznow/frontend/plugins/vuetify.ts";
export default [
  revive_payload_client_2XIogNDk2G,
  unhead_g1L03FiiLH,
  router_TZdSlX6ySW,
  payload_client_wAEPFY9SpE,
  navigation_repaint_client_ULsxrkOfcv,
  check_outdated_build_client_afiTcdwAnF,
  chunk_reload_client_OBzwLp0BCn,
  components_plugin_KR1HBZs4kY,
  prefetch_client_pdjnCvlmDB,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_ZUfkpjEMoJ,
  plugin_t0BBAUc628,
  plugin_1F6fXCq7hD,
  plugin_52sLyo1tNa,
  error_handler_kEP5FliEXj,
  observability_vJGwVPmCRv,
  timeago_wa2DbggHAx,
  toastification_xLadjaQwvu,
  vuetify_7h9QAQEssH
]