import { NonEmptyString2k } from "effect-app/Schema"

import { Ticket, TicketId, TicketMessage } from "#models/Ticket"
import { User } from "#models/User"
import { S } from "#resources/lib"

// AddMessage
export class AddMessage extends S.Req<AddMessage>()("addMessage", {
  ...TicketMessage.omit("id", "createdAt", "author"),
  ticketId: TicketId
}, { allowedRoles: ["user"] }) {}

// Change status
export class ChangeStatus extends S.Req<ChangeStatus>()("changeStatus", {
  ticketId: Ticket.fields["id"],
  status: Ticket.fields["status"]
}, { allowedRoles: ["user"] }) {}

// Create ticket
export class CreateTicketInput extends S.ExtendedClass<
  CreateTicketInput,
  CreateTicketInput.Encoded
>()({
  title: Ticket.fields["title"],
  message: NonEmptyString2k
}) {}

export class Create extends S.Req<Create>()("createTicket", {
  ...CreateTicketInput.fields
}, { allowedRoles: ["user"] }) {}

// Get

export class Author extends S.Class<Author>()({
  ...User.pick("id", "displayName", "role", "email")
}) {}

export class TicketMessageWithAuthor extends S.Class<TicketMessageWithAuthor>()({
  ...TicketMessage.omit("author"),
  // plain Author object, so we duplicate Author objects over the wire, instead of sending just the id, and using batched RequestResolver to fetch the authors deduplicated.
  // https://github.com/effect-app/sample/blob/main/api/src/resources/resolvers/UserResolver.ts#L39
  author: Author
}) {}

export class TicketWithAuthor extends S.Class<TicketWithAuthor>()({
  ...Ticket.omit("author"),
  // plain Author object, so we duplicate Author objects over the wire, instead of sending just the id, and using batched RequestResolver to fetch the authors deduplicated.
  // https://github.com/effect-app/sample/blob/main/api/src/resources/resolvers/UserResolver.ts#L39
  author: Author,
  messages: S.NonEmptyArray(TicketMessageWithAuthor)
}) {}

export class Get extends S.Req<Get>()("getTicket", {
  ticketId: TicketId
}, { allowedRoles: ["user"], success: TicketWithAuthor }) {}

// Index
export class IndexTicketsResponse extends S.Class<IndexTicketsResponse>()({
  tickets: S.Array(TicketWithAuthor)
}) {}

export class Index extends S.Req<Index>()("indexTickets", {}, {
  allowedRoles: ["user", "manager"],
  success: IndexTicketsResponse
}) {}

// codegen:start {preset: model}
//
/* eslint-disable */
export namespace CreateTicketInput {
  export interface Encoded extends S.Struct.Encoded<typeof CreateTicketInput["fields"]> {}
}
/* eslint-enable */
//
// codegen:end

// codegen:start {preset: meta, sourcePrefix: src/resources/}
export const meta = { moduleName: "Tickets" } as const
// codegen:end
