import { useSafeQuery, clientFor } from "~/composables/client"
import { AccountsRsc, SubscriptionsV2Rsc } from "#resources"
import { computed } from "vue"
import { useAuthUser } from "./currentUser"
import { Plan } from "#models/Plan"

export const usePortfolio = () => {
  const subscriptionsV2 = clientFor(SubscriptionsV2Rsc)
  const [, portfolio] = useSafeQuery(subscriptionsV2.IndexSubscriptionsV2)
  return portfolio
}

export const useCurrentSubscriptionsNumber = () => {
  const portfolio = usePortfolio()
  return computed(() => portfolio.value?.items.length || 0)
}

export const useUserPlan = () => {
  const accountsClient = clientFor(AccountsRsc)
  const [_, user] = useSafeQuery(accountsClient.GetMe)

  return computed(() => user.value?.plan)
}

export const isPremiumUser = () => {
  const user = useAuthUser()
  return (Plan.Paid.tags.literals as readonly string[]).includes(user.plan)
}
