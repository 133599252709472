<template>
  <span
    :class="`fi fi-${countryCode.toLowerCase()} fi ${styling} ${props.class ?? ''}`"
  ></span>
</template>

<script setup lang="ts">
import { computed } from "vue"

const props = defineProps<{
  countryCode: string
  size?: "small" | "normal" | "large"
  class?: string
}>()

const styling = computed(() =>
  props?.size === "small"
    ? "small-flag-icon"
    : props?.size === "large"
      ? "large-flag-icon"
      : "normal-flag-icon",
)
</script>

<style lang="scss">
.small-flag-icon {
  font-size: 1rem;
  line-height: 1rem;
  height: 1rem;
  width: 1rem;
}

.normal-flag-icon {
  font-size: 1.5rem;
  line-height: 1.5rem;
  height: 1.5rem;
  width: 1.5rem;
}

.large-flag-icon {
  height: 5em;
  width: 4em;
}

.normal-flag-icon,
.small-flag-icon {
  vertical-align: middle;
}
</style>
