<template>
  <Suspender :on-error="_ => true">
    <template #error="{ error }">
      <v-container>
        <error-cause
          v-if="Runtime.isFiberFailure(error)"
          :cause="error[Runtime.FiberFailureCauseId]"
        />
        <p v-else>
          An unexpected error occurred
          <span v-if="config.public.env === 'local-dev'">{{ error }}</span>
        </p>
      </v-container>
    </template>
    <slot />
  </Suspender>
</template>

<script setup lang="ts">
import { useRuntimeConfig } from "#imports"
import { Runtime } from "effect"
import Suspender from "./Suspender.vue"

const config = useRuntimeConfig()
</script> 
