import validate from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/nuxt@3.13.2_@azure+cosmos@4.2.0_@azure+storage-blob@12.26.0_@parcel+watcher@2.4.1_@types+node_v3s32t47cdhzzvnrdiyetky7pi/node_modules/nuxt/dist/pages/runtime/validate.js";
import feature_45flag_45global from "/home/runner/work/signalznow/signalznow/frontend/middleware/featureFlag.global.ts";
import manifest_45route_45rule from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/nuxt@3.13.2_@azure+cosmos@4.2.0_@azure+storage-blob@12.26.0_@parcel+watcher@2.4.1_@types+node_v3s32t47cdhzzvnrdiyetky7pi/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  feature_45flag_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  manager: () => import("/home/runner/work/signalznow/signalznow/frontend/middleware/manager.ts"),
  "test-only": () => import("/home/runner/work/signalznow/signalznow/frontend/middleware/test-only.ts"),
  "sidebase-auth": () => import("/home/runner/work/signalznow/signalznow/node_modules/.pnpm/@sidebase+nuxt-auth@0.10.0_@azure+cosmos@4.2.0_@azure+storage-blob@12.26.0_encoding@0.1.13_ma_pooi2lqa6bglmcyuq2eumdv5t4/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/sidebase-auth.js")
}