import { Plan, Recurrence } from "#models/Plan"
import { Role, User, UserId } from "#models/User"
import { S } from "#resources/lib"
import { UserProfileId } from "effect-app/ids"
import type { B, Schema } from "effect-app/Schema"
import { Email, fromBrand, makeOptional, nominal, NonEmptyString255, NonEmptyString2k } from "effect-app/Schema"

// Get
export class GetMe extends S.Req<GetMe>()("GetMe", {}, { allowedRoles: ["user"], success: User }) {}

// Get Last Pyment Status
const PaymentStatus = S.Literal(
  "open",
  "canceled",
  "pending",
  "authorized",
  "expired",
  "failed",
  "paid",
  "plan_not_found",
  "payment_not_found",
  "unauthorized"
)

export class GetLastPaymentStatusResponse extends S.Class<GetLastPaymentStatusResponse>()({
  status: PaymentStatus
}) {}

export class GetLastPaymentStatus extends S.Req<GetLastPaymentStatus>()("GetLastPaymentStatus", {}, {
  allowedRoles: ["user"],
  success: GetLastPaymentStatusResponse
}) {}

// Get User
export const GetUsersResponse = S.Array(UserId)
export type GetUsersResponse = Schema.Type<typeof GetUsersResponse>

export class GetUsers
  extends S.Req<GetUsers>()("GetUsers", {}, { allowedRoles: ["user"], allowAnonymous: true, success: GetUsersResponse })
{}

export const RegisterMeInput = S.Struct({
  auth0Id: UserProfileId,
  email: Email,
  name: NonEmptyString255,
  roles: S.Array(Role)
})

// Register
/**
 * Gets called during auth0 login, so new users are created, and existing users updated
 */
export class RegisterMe extends S.Req<RegisterMe>()("RegisterMe", {
  input: RegisterMeInput,
  secret: S.Redacted(NonEmptyString255)
}, { allowedRoles: ["user"], allowAnonymous: true }) {}

// Subscribe
const SubscribeAction = S.Union(
  S.Struct({ _tag: S.Literal("first_payment"), checkoutUrl: NonEmptyString2k }),
  S.Struct({ _tag: S.Literal("poll") }),
  S.Struct({ _tag: S.Literal("already_subscribed"), plan: Plan }),
  S.Struct({ _tag: S.Literal("subscription_cancelled") }),
  S.Struct({ _tag: S.Literal("subscription_updated"), plan: Plan })
)

export class SubscribeResponse extends S.Class<SubscribeResponse>()({
  action: S.NullOr(SubscribeAction)
}) {}

export class Subscribe extends S.Req<Subscribe>()("Subscribe", {
  planType: Plan.Selectable.tags,
  recurrence: Recurrence,
  hasAcceptedTerms: S.Boolean
}, { allowedRoles: ["user"], success: SubscribeResponse }) {}

export class TrialUpgrade extends S.Req<TrialUpgrade>()("TrialUpgrade", {
  userId: UserId,
  expiresAt: S.Date
}, { allowedRoles: ["manager"] }) {}

export class GuestUpgrade extends S.Req<GuestUpgrade>()("GuestUpgrade", {
  userId: UserId
}, { allowedRoles: ["manager"] }) {}

// Upload

export interface PasswordInputBrand extends B.Brand<"UserProfileId"> {}
export type PasswordInput = string & PasswordInputBrand
export const PasswordInput = S.String.pipe(
  S.minLength(8),
  S.filter((password) => {
    const hasUpperCase = /[A-Z]/.test(password)
    const hasLowerCase = /[a-z]/.test(password)
    const hasNumbers = /\d/.test(password)
    const hasNonalphas = /\W/.test(password)
    return hasUpperCase && hasLowerCase && hasNumbers && hasNonalphas
  }),
  fromBrand(nominal<PasswordInput>(), { jsonSchema: {}, identifier: "PasswordInput" })
)
export class UpdateMeInput extends S.ExtendedClass<UpdateMeInput, UpdateMeInput.Encoded>()({
  ...makeOptional(User.pick("locale", "displayName", "email")),
  password: S.optional(PasswordInput)
}) {}

export class UpdateMe extends S.Req<UpdateMe>()("UpdateMe", UpdateMeInput.fields, { allowedRoles: ["user"] }) {}

export class PingMe extends S.Req<PingMe>()("PingMe", {}, { allowedRoles: ["user"] }) {}

export class UserListResponse extends S.Class<UserListResponse>()({
  items: S.Array(User)
}) {}

export class Index extends S.Req<Index>()("Index", {}, { allowedRoles: ["manager"], success: UserListResponse }) {}

export class Stats extends S.Req<Stats>()("Stats", {}, {
  allowedRoles: ["manager"],
  success: S.Struct({
    usersActive24Hours: S.Number,
    usersActiveLastWeek: S.Number,
    newUsersLast24Hours: S.Number,
    newUsersLastWeek: S.Number,
    totalUsers: S.Number,
    freePlanUsers: S.Number,
    basicPlanUsers: S.Number,
    proPlanUsers: S.Number,
    guruPlanUsers: S.Number
  })
}) {}

export class Get extends S.Req<Get>()("Get", { id: User.fields.id }, { allowedRoles: ["manager"], success: User }) {}

// codegen:start {preset: model}
//
/* eslint-disable */
export namespace UpdateMeInput {
  export interface Encoded extends S.Struct.Encoded<typeof UpdateMeInput["fields"]> {}
}
/* eslint-enable */
//
// codegen:end

// codegen:start {preset: meta, sourcePrefix: "src/resources/"}
export const meta = { moduleName: "Accounts" } as const
// codegen:end
