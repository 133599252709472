import { defineComponent, onErrorCaptured, ref } from "vue"
import { useNuxtApp, useRouter } from "nuxt/app"

export default defineComponent({
  name: "NuxtErrorBoundary",
  inheritAttrs: false,
  emits: {
    error(_error: unknown) {
      return true
    },
  },
  setup(_props, { emit, slots }) {
    const error = ref<Error | null>(null)
    const nuxtApp = useNuxtApp()

    if (import.meta.client) {
      onErrorCaptured((err, target, info) => {
        if (!nuxtApp.isHydrating || !nuxtApp.payload.serverRendered) {
          emit("error", err)
          nuxtApp.hooks.callHook("vue:error", err, target, info)
          error.value = err
          return false
        }
      })
    }

    function clearError() {
      error.value = null
    }

    // PRO: fix error not clearing after route change: https://github.com/nuxt/nuxt/issues/15781#issuecomment-2320928163
    const router = useRouter()
    router.afterEach(() => {
      clearError()
    })

    return () =>
      error.value
        ? // PRO: fix passing raw error instead of ref to template: TBD
          slots.error?.({ error: error.value, clearError })
        : slots.default?.()
  },
})
