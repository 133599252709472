<template>
  <ErrorBoundary>
    <slot />
  </ErrorBoundary>
</template>
<script setup lang="ts">
import { onErrorCaptured } from "vue"

onErrorCaptured(err => {
  // silently ignore CancelledError
  console.warn("cancelled error captured", err)
  return !err.message.includes("CancelledError")
})
</script>
