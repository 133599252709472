<script setup lang="ts">
import { useRuntimeConfig } from "#app"
import type { UserNotAuthorError } from "#models/Ticket"
import { Cause, Either, Match } from "effect-app"
import type { SupportedErrors } from "effect-app/client"

defineProps<{ cause: Cause.Cause<unknown> }>()
const config = useRuntimeConfig()
</script>

<template>
  <div>
    {{
          Cause.failureOrCause(cause)
          .pipe(Either.match({ onLeft: (error) => Match.value(error as SupportedErrors | UserNotAuthorError).pipe(
            Match.tags({
              NotFoundError: () => "Nicht gefunden",
              NotLoggedInError: () => "Sie mussen eingelogt sein",
              UserNotAuthorError: () => "Nicht gefunden", // actually it should be mapped to a standard 404... or a 403, but hiding is generally more acceptable for gets..
              UnauthorizedError: () =>
                "Sie sind nicht berechtigt, diese Aktion auszuführen",
            }),
            Match.orElse(
              () =>
                "Es ist ein Fehler aufgetreten. Wir wurden benachrichtigt und werden das Problem in Kürze beheben. Versuchen Sie es erneut.",
            )
            ), onRight: (cause) => Cause.isInterrupted(cause) ? "Die Anfrage wurde unterbrochen" : "Es ist ein Fehler aufgetreten. Wir wurden benachrichtigt und werden das Problem in Kürze beheben. Versuchen Sie es erneut."})
          )
    }}
    <div v-if="config.public.env === 'local-dev'">
      dev details: {{ Cause.pretty(cause) }}
    </div>
  </div>
</template>