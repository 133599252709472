import { Data, Effect } from "effect-app"

class ClarityError extends Data.Error<{ message: string }> {}

export function useClarity() {
  const emitCustomEvent = (eventName: string) =>
    Effect.gen(function* () {
      if ("clarity" in window) {
        const clarity = (
          window.clarity as (_: string, __: string) => Promise<unknown>
        ).bind(window)
        yield* Effect.tryPromise({
          try: async () => clarity("event", eventName),
          catch: e =>
            new ClarityError({
              message: e instanceof Error ? e.message : `Clarity error: ${e}`,
            }),
        })
      } else {
        return yield* new ClarityError({ message: "Clarity is not loaded" })
      }
    })

  const emitCustomEventLogError = (eventName: string) =>
    Effect.match(emitCustomEvent(eventName), {
      onFailure: e => console.error(e),
      onSuccess: () => {},
    })

  const giveCookieConsent = () => {
    if ("clarity" in window) {
      ;(window.clarity as (_: string) => Promise<unknown>)("consent")
    } else {
      console.error("Clarity is not loaded")
    }
  }

  const removeCookieConsent = () => {
    if ("clarity" in window) {
      ;(window.clarity as (_: string, __: false) => Promise<unknown>)(
        "consent",
        false,
      )
    } else {
      console.error("Clarity is not loaded")
    }
  }

  return {
    emitCustomEvent,
    emitCustomEventLogError,
    giveCookieConsent,
    removeCookieConsent,
  }
}
