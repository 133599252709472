import { ISIN, Security, TickerSymbol, Translated } from "#models/Security"
import { S } from "#resources/lib"
import { OperationId } from "effect-app/Operations"

// Download
export class SecurityEntry extends S.Class<SecurityEntry>()({
  name: S.String,
  isin: ISIN,
  tickerMarketplace: TickerSymbol,

  entryAlgo: S.String,
  entryUnit_Rolling_only: S.NullOr(S.Number),
  entryPeriod: S.Number,
  entryMultiplier: S.Number,

  exitAlgo: S.String,
  exitUnit_Rolling_only: S.NullOr(S.Number),
  exitPeriod: S.Number,
  exitMultiplier: S.Number
}) {}

export class DownloadListResponse extends S.Class<DownloadListResponse>()({
  items: S.Array(SecurityEntry)
}) {}

export class UpdateSecurity extends S.Req<UpdateSecurity>()("UpdateSecurity", {
  securityId: Security.fields.isin,
  input: S.Struct({
    logo: S.optional(S.Uint8Array),
    summary: S.optional(Translated)
  })
}, { allowedRoles: ["manager"] }) {}

export class DownloadList
  extends S.Req<DownloadList>()("DownloadList", {}, { allowedRoles: ["manager"], success: DownloadListResponse })
{}

// Import
export class ImportListType extends S.Class<ImportListType>()({
  name: S.String,
  content: S.Uint8Array
}) {}

export class ImportPickListInput extends S.Class<ImportPickListInput>()({
  lists: S.Array(ImportListType)
}) {}

export const ImportListResponse = OperationId
// export type ImportListResponse = OperationId

export class ImportList extends S.Req<ImportList>()("ImportList", ImportPickListInput.fields, {
  allowedRoles: ["manager"],
  success: ImportListResponse
}) {}

export class ResetEodData extends S.Req<ResetEodData>()("ResetEodData", {
  symbol: TickerSymbol
}, { allowedRoles: ["manager"] }) {}

// codegen:start {preset: meta, sourcePrefix: "src/resources/"}
export const meta = { moduleName: "Admin/Import" } as const
// codegen:end
