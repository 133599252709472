import { default as _91_46_46_46slug_93qEtE8Ua5mZMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/[...slug].vue?macro=true";
import { default as activateriMeX1vGSTMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/activate.vue?macro=true";
import { default as _UploadbyZbrNbThvMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/_Upload.vue?macro=true";
import { default as indexalBVaM9TGjMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/index.vue?macro=true";
import { default as searchkbPGv88R3EMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/news/_components/search.vue?macro=true";
import { default as searchBarQiN482ciayMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/news/_components/searchBar.vue?macro=true";
import { default as _91news_93imyGOKIDL8Meta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/news/[news].vue?macro=true";
import { default as createipPJFFut8kMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/news/create.vue?macro=true";
import { default as indexpD3gZgjmQ2Meta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/news/index.vue?macro=true";
import { default as searchfvEcJre83VMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/news/search.vue?macro=true";
import { default as index0HCGZghy6QMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/optimizations/index.vue?macro=true";
import { default as indexew7JhUuvqPMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/playground/index.vue?macro=true";
import { default as indexeBIHGb8WYgMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/securities/index.vue?macro=true";
import { default as _91id_933TIn2T8hxCMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/securities/ticker-group/[id].vue?macro=true";
import { default as createJfT4AxBc10Meta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/securities/ticker-group/create.vue?macro=true";
import { default as _91ticker_93lM4HiLAkEGMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/securities/tickers/[ticker].vue?macro=true";
import { default as uploadHElWSJ1H77Meta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/upload.vue?macro=true";
import { default as _91id_93UJ2AnKZukoMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/users/[id].vue?macro=true";
import { default as indexUV3LhZS0mSMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/users/index.vue?macro=true";
import { default as adminfPMvoGDmihMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin.vue?macro=true";
import { default as indexR7PLGTkS3wMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/index.vue?macro=true";
import { default as logoutQqmC98mighMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/logout.vue?macro=true";
import { default as _91news_93G1du0mrlQqMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/news/[news].vue?macro=true";
import { default as indexyTuHShTShUMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/news/index.vue?macro=true";
import { default as _91news_93Sz0oStgsCKMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/news/show/[news].vue?macro=true";
import { default as indexMd5kvF6KpSMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/portfolio/index.vue?macro=true";
import { default as _PrivacyVNlchbxTFJMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/privacy/_Privacy.vue?macro=true";
import { default as indexRFUq95oUn9Meta } from "/home/runner/work/signalznow/signalznow/frontend/pages/privacy/index.vue?macro=true";
import { default as refreshnlKOKyRCKbMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/refresh.vue?macro=true";
import { default as searchdP3OveaFqXMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/search.vue?macro=true";
import { default as _CommentsqiIpAtenQaMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/securities/_Comments.vue?macro=true";
import { default as _EtfTablegB87s2g7cpMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/securities/_EtfTable.vue?macro=true";
import { default as _PremiumDialogapWMFkpcguMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/securities/_PremiumDialog.vue?macro=true";
import { default as _SelectStockIndexFUscMgeaxbMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/securities/_SelectStockIndex.vue?macro=true";
import { default as _SubscriptionToggle501kNWEe4KMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/securities/_SubscriptionToggle.vue?macro=true";
import { default as _TickerezmuWIXvvpMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/securities/_Ticker.vue?macro=true";
import { default as _91ticker_93bIeWl2cKMtMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/securities/[ticker].vue?macro=true";
import { default as indexhnjWtqHUFzMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/securities/index.vue?macro=true";
import { default as settingsKguEJggsvEMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/settings.vue?macro=true";
import { default as _91plan_93c2WoRDFvFXMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/subscribe/[plan].vue?macro=true";
import { default as already_45subscribedmncsMF6Ol5Meta } from "/home/runner/work/signalznow/signalznow/frontend/pages/subscribe/already-subscribed.vue?macro=true";
import { default as indexAhzTfX9t5AMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/subscribe/index.vue?macro=true";
import { default as processingiQt3sNeWMlMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/subscribe/processing.vue?macro=true";
import { default as unsubscribedmsoE88LYeDMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/subscribe/unsubscribed.vue?macro=true";
import { default as updatedB4NZe8kH4vMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/subscribe/updated.vue?macro=true";
import { default as _91ticket_93yn2Hy6o8vGMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/support/[ticket].vue?macro=true";
import { default as create_45ticketuLXlkpU4TIMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/support/create-ticket.vue?macro=true";
import { default as indexwauqe9wkZAMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/support/index.vue?macro=true";
import { default as _Termse1dFGJsO9VMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/terms/_Terms.vue?macro=true";
import { default as accept0bzZwFwc2QMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/terms/accept.vue?macro=true";
import { default as indexXgy6NKxfDjMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/terms/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93qEtE8Ua5mZMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/[...slug].vue")
  },
  {
    name: "activate",
    path: "/activate",
    meta: activateriMeX1vGSTMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/activate.vue")
  },
  {
    name: adminfPMvoGDmihMeta?.name,
    path: "/admin",
    meta: adminfPMvoGDmihMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin.vue"),
    children: [
  {
    name: "admin-_Upload",
    path: "_Upload",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/_Upload.vue")
  },
  {
    name: "admin",
    path: "",
    meta: indexalBVaM9TGjMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/index.vue")
  },
  {
    name: "admin-news-_components-search",
    path: "news/_components/search",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/news/_components/search.vue")
  },
  {
    name: "admin-news-_components-searchBar",
    path: "news/_components/searchBar",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/news/_components/searchBar.vue")
  },
  {
    name: "admin-news-news",
    path: "news/:news()",
    meta: _91news_93imyGOKIDL8Meta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/news/[news].vue")
  },
  {
    name: "admin-news-create",
    path: "news/create",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/news/create.vue")
  },
  {
    name: "admin-news",
    path: "news",
    meta: indexpD3gZgjmQ2Meta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/news/index.vue")
  },
  {
    name: "admin-news-search",
    path: "news/search",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/news/search.vue")
  },
  {
    name: "admin-optimizations",
    path: "optimizations",
    meta: index0HCGZghy6QMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/optimizations/index.vue")
  },
  {
    name: "admin-playground",
    path: "playground",
    meta: indexew7JhUuvqPMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/playground/index.vue")
  },
  {
    name: "admin-securities",
    path: "securities",
    meta: indexeBIHGb8WYgMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/securities/index.vue")
  },
  {
    name: "admin-securities-ticker-group-id",
    path: "securities/ticker-group/:id()",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/securities/ticker-group/[id].vue")
  },
  {
    name: "admin-securities-ticker-group-create",
    path: "securities/ticker-group/create",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/securities/ticker-group/create.vue")
  },
  {
    name: "admin-securities-tickers-ticker",
    path: "securities/tickers/:ticker()",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/securities/tickers/[ticker].vue")
  },
  {
    name: "admin-upload",
    path: "upload",
    meta: uploadHElWSJ1H77Meta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/upload.vue")
  },
  {
    name: "admin-users-id",
    path: "users/:id()",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/users/[id].vue")
  },
  {
    name: "admin-users",
    path: "users",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/users/index.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexR7PLGTkS3wMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/index.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutQqmC98mighMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/logout.vue")
  },
  {
    name: "news-news",
    path: "/news/:news()",
    meta: _91news_93G1du0mrlQqMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/news/[news].vue")
  },
  {
    name: "news",
    path: "/news",
    meta: indexyTuHShTShUMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/news/index.vue")
  },
  {
    name: "news-show-news",
    path: "/news/show/:news()",
    meta: _91news_93Sz0oStgsCKMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/news/show/[news].vue")
  },
  {
    name: "portfolio",
    path: "/portfolio",
    meta: indexMd5kvF6KpSMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/portfolio/index.vue")
  },
  {
    name: "privacy-_Privacy",
    path: "/privacy/_Privacy",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/privacy/_Privacy.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: indexRFUq95oUn9Meta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/privacy/index.vue")
  },
  {
    name: "refresh",
    path: "/refresh",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/refresh.vue")
  },
  {
    name: "search",
    path: "/search",
    meta: searchdP3OveaFqXMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/search.vue")
  },
  {
    name: "securities-_Comments",
    path: "/securities/_Comments",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/securities/_Comments.vue")
  },
  {
    name: "securities-_EtfTable",
    path: "/securities/_EtfTable",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/securities/_EtfTable.vue")
  },
  {
    name: "securities-_PremiumDialog",
    path: "/securities/_PremiumDialog",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/securities/_PremiumDialog.vue")
  },
  {
    name: "securities-_SelectStockIndex",
    path: "/securities/_SelectStockIndex",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/securities/_SelectStockIndex.vue")
  },
  {
    name: "securities-_SubscriptionToggle",
    path: "/securities/_SubscriptionToggle",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/securities/_SubscriptionToggle.vue")
  },
  {
    name: "securities-_Ticker",
    path: "/securities/_Ticker",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/securities/_Ticker.vue")
  },
  {
    name: "securities-ticker",
    path: "/securities/:ticker()",
    meta: _91ticker_93bIeWl2cKMtMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/securities/[ticker].vue")
  },
  {
    name: "securities",
    path: "/securities",
    meta: indexhnjWtqHUFzMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/securities/index.vue")
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsKguEJggsvEMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/settings.vue")
  },
  {
    name: "subscribe-plan",
    path: "/subscribe/:plan()",
    meta: _91plan_93c2WoRDFvFXMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/subscribe/[plan].vue")
  },
  {
    name: "subscribe-already-subscribed",
    path: "/subscribe/already-subscribed",
    meta: already_45subscribedmncsMF6Ol5Meta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/subscribe/already-subscribed.vue")
  },
  {
    name: "subscribe",
    path: "/subscribe",
    meta: indexAhzTfX9t5AMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/subscribe/index.vue")
  },
  {
    name: "subscribe-processing",
    path: "/subscribe/processing",
    meta: processingiQt3sNeWMlMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/subscribe/processing.vue")
  },
  {
    name: "subscribe-unsubscribed",
    path: "/subscribe/unsubscribed",
    meta: unsubscribedmsoE88LYeDMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/subscribe/unsubscribed.vue")
  },
  {
    name: "subscribe-updated",
    path: "/subscribe/updated",
    meta: updatedB4NZe8kH4vMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/subscribe/updated.vue")
  },
  {
    name: "support-ticket",
    path: "/support/:ticket()",
    meta: _91ticket_93yn2Hy6o8vGMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/support/[ticket].vue")
  },
  {
    name: "support-create-ticket",
    path: "/support/create-ticket",
    meta: create_45ticketuLXlkpU4TIMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/support/create-ticket.vue")
  },
  {
    name: "support",
    path: "/support",
    meta: indexwauqe9wkZAMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/support/index.vue")
  },
  {
    name: "terms-_Terms",
    path: "/terms/_Terms",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/terms/_Terms.vue")
  },
  {
    name: "terms-accept",
    path: "/terms/accept",
    meta: accept0bzZwFwc2QMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/terms/accept.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: indexXgy6NKxfDjMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/terms/index.vue")
  }
]