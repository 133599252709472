/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeClient } from "@effect-app/vue"
import { useToast } from "vue-toastification"
import { useIntl } from "./intl"
import { runtime, type RT } from "./runtime"
import type {} from "effect-app/Schema/brand"
import { clientFor as clientFor_ } from "#resources/lib"
import type { Requests } from "effect-app/client"
import { OperationsClient } from "#resources/Operations"
import * as Result from "@effect-rx/rx/Result"
import type {
  InitialDataFunction,
  QueryObserverResult,
  RefetchOptions,
  UseQueryReturnType,
} from "@tanstack/vue-query"
import { Effect, Exit, Runtime } from "effect-app"
import type {
  RequestHandler,
  RequestHandlerWithInput,
  TaggedRequestClassAny,
} from "effect-app/client/clientFor"
import { shallowRef, type ComputedRef, type WatchSource } from "vue"
import type {
  QueryObserverOptionsCustom,
  KnownFiberFailure,
} from "@effect-app/vue/query"

export { useToast } from "vue-toastification"

export { Result, type MutationResult, makeContext } from "@effect-app/vue"
export {
  pauseWhileProcessing,
  useIntervalPauseWhileProcessing,
  composeQueries,
  SuppressErrors,
  mapHandler,
} from "@effect-app/vue"

export const run = runtime.runPromise
export const runSync = runtime.runSync

export const clientFor = <M extends Requests>(m: M) => runSync(clientFor_(m))
export const useOperationsClient = () => runSync(OperationsClient)

export const {
  buildFormFromSchema,
  makeUseAndHandleMutation,
  useAndHandleMutation,
  useSafeMutation,
  useSafeMutationWithState,
  useSafeQuery,
} = makeClient(useIntl, useToast, shallowRef(runtime.runtime)) // TODO

// TODO: extract to @effect-app/vue
/**
 * The difference with useSafeQuery is that this function will return a Promise you can await in the Setup,
 * which ensures that either there always is a latest value, or an error occurs on load.
 * So that Suspense and error boundaries can be used.
 */
export function useSafeSuspenseQuery<
  E,
  A,
  Request extends TaggedRequestClassAny,
>(
  self: RequestHandler<A, E, RT, Request>,
  options?: QueryObserverOptionsCustom<A, E> & {
    initialData: A | InitialDataFunction<A>
  },
): Effect<
  readonly [
    ComputedRef<Result.Result<A, E>>,
    ComputedRef<A>,
    (
      options?: RefetchOptions,
    ) => Effect<QueryObserverResult<A, KnownFiberFailure<E>>>,
    UseQueryReturnType<any, any>,
  ]
>
export function useSafeSuspenseQuery<
  Arg,
  E,
  A,
  Request extends TaggedRequestClassAny,
>(
  self: RequestHandlerWithInput<Arg, A, E, RT, Request>,
  arg: Arg | WatchSource<Arg>,
  options?: QueryObserverOptionsCustom<A, E> & {
    initialData: A | InitialDataFunction<A>
  },
): Effect<
  readonly [
    ComputedRef<Result.Result<A, E>>,
    ComputedRef<A>,
    (
      options?: RefetchOptions,
    ) => Effect<QueryObserverResult<A, KnownFiberFailure<E>>>,
    UseQueryReturnType<any, any>,
  ]
>
export function useSafeSuspenseQuery<
  E,
  A,
  Request extends TaggedRequestClassAny,
>(
  self: RequestHandler<A, E, RT, Request>,
  options?: QueryObserverOptionsCustom<A, E>,
): Effect<
  readonly [
    ComputedRef<Result.Result<A, E>>,
    ComputedRef<A>,
    (
      options?: RefetchOptions,
    ) => Effect<QueryObserverResult<A, KnownFiberFailure<E>>>,
    UseQueryReturnType<any, any>,
  ]
>
export function useSafeSuspenseQuery<
  Arg,
  E,
  A,
  Request extends TaggedRequestClassAny,
>(
  self: RequestHandlerWithInput<Arg, A, E, RT, Request>,
  arg: Arg | WatchSource<Arg>,
  options?: QueryObserverOptionsCustom<A, E>,
): Effect<
  readonly [
    ComputedRef<Result.Result<A, E>>,
    ComputedRef<A>,
    (
      options?: RefetchOptions,
    ) => Effect<QueryObserverResult<A, KnownFiberFailure<E>>>,
    UseQueryReturnType<any, any>,
  ]
>
export function useSafeSuspenseQuery(
  self: any,
  argOrOptions?: any,
  options?: any,
) {
  const [resultRef, latestRef, fetch, uqrt] = useSafeQuery(
    self,
    argOrOptions,
    options,
  )
  return Effect.gen(function* () {
    // we want to throw on error so that we can catch cancelled error and skip handling it
    const r = yield* fetch()
    if (
      r &&
      r.error && // unwrap the FiberFailure, as we are going through runPromise
      Runtime.isFiberFailure(r.error)
    ) {
      return yield* Exit.failCause(r.error[Runtime.FiberFailureCauseId])
    }
    // Effect.promise(() => uqrt.suspense()) // what's the difference with just calling `fetch` ?

    const result = resultRef.value
    if (Result.isInitial(result)) {
      return yield* Effect.die(
        "Internal Error: Promise should be resolved already",
      )
    }
    if (Result.isFailure(result)) {
      return yield* Exit.failCause(result.cause)
    }

    return [resultRef, latestRef, fetch, uqrt] as const
  })
}
