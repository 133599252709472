import { S } from "effect-app"
import { NonEmptyString255, type NonEmptyString2kBrand } from "effect-app/Schema"
import { type Brand } from "effect/Brand"
import type { Simplify } from "effect/Types"

/**
 * The "common" name of a stock exchange.
 * Example for the US exchange Nasdaq: "NASDAQ".
 * Note that the full name of the exchange is "NASDAQ Stock Exchange".
 */
export const Acronym = S.String
export type Acronym = string

export interface ISOCodeBrand extends Simplify<Brand<"ISOCode"> & NonEmptyString2kBrand> {
}

/**
 * 2 character code used to identify countries.
 * Example for the US exchange NASDAQ: "US"
 */
export type ISOCode = string & ISOCodeBrand

export const ISOCode = S
  .String
  .pipe(
    S.length(2),
    S.uppercased(),
    S.filter((s): s is ISOCode => /^[A-Z]{2}$/.test(s), { description: "a valid ISOCode like 'US'" }),
    S.fromBrand(S.nominal<ISOCodeBrand>()),
    S.withDefaultMake
  )

export interface MICBrand extends Simplify<Brand<"MIC"> & NonEmptyString2kBrand> {
}

/**
 * Market Identifier Code is a 4 character code used to identify stock exchanges.
 * See: https://www.iso20022.org/market-identifier-codes
 * Example for the US exchange NASDAQ: "XNAS"
 * TODO: add validation for MICs?
 */
export type MIC = string & MICBrand

export const MIC = S
  .String
  .pipe(
    S.minLength(4),
    S.maxLength(8),
    S.uppercased(),
    S.fromBrand(S.nominal<MICBrand>()),
    S.withDefaultMake
  )

export class StockExchange extends S.ExtendedClass<StockExchange, StockExchange.Encoded>()({
  id: MIC,
  acronym: S.NullOr(Acronym),
  city: S.NullOr(NonEmptyString255),
  country: S.NullOr(NonEmptyString255),
  countryCode: ISOCode,
  name: S.NullOr(NonEmptyString255)
}) {}

// codegen:start {preset: model}
//
/* eslint-disable */
export namespace StockExchange {
  export interface Encoded extends S.Struct.Encoded<typeof StockExchange["fields"]> {}
}
/* eslint-enable */
//
// codegen:end
