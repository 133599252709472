import { S } from "effect-app"
import { Date, NonEmptyString2k, StringId } from "effect-app/Schema"
import { UserFromId } from "./User.js"

export const TicketMessageId = StringId
export type TicketMessageId = StringId

const author = UserFromId
  .pipe(S.propertySignature, S.fromKey("authorId"))

export class TicketMessage extends S.ExtendedClass<TicketMessage, TicketMessage.Encoded>()({
  id: TicketMessageId.withDefault,
  author,
  //  authorRole: Role,
  content: NonEmptyString2k,
  createdAt: Date.withDefault
}) {}

export const TicketId = StringId
export type TicketId = StringId

export class Ticket extends S.ExtendedClass<Ticket, Ticket.Encoded>()({
  id: TicketId.withDefault,
  author,
  title: NonEmptyString2k,
  createdAt: Date.withDefault,
  messages: S.NonEmptyArray(TicketMessage),
  status: S.Literal("open", "closed").pipe(S.withDefaultConstructor(() => "open" as const))
}) {}

// export class UserNotAuthorError extends UnauthorizedError {
//   override readonly message = "User is not the author of the ticket"
// }

export class UserNotAuthorError extends S.TaggedError<UserNotAuthorError>()("UserNotAuthorError", {}) {
  override readonly message = "User is not the author of the ticket"

  // static readonly type = "UnauthorizedError"
  // readonly MapsToError = UnauthorizedError // for the status code mapping? kinda shit, though we have to map somewhere..
  // perhaps better map on the Request? but then you have to do it for all cases.
  // if we map globally, it's also a mess.
  // we could also map all domain errors to DomainError, leading to e.g 422 code, but then the codes become less useful, 403 is very helpful here.
  // perhaps we could map per resource, and globally? and only if an exception, otherwise it's 422 by default..
}

export const makeDomainError = <Self extends S.Schema.Any>(self: Self) =>
  S.TaggedStruct("DomainError", { details: self })

// codegen:start {preset: model}
//
/* eslint-disable */
export namespace TicketMessage {
  export interface Encoded extends S.Struct.Encoded<typeof TicketMessage["fields"]> {}
}
export namespace Ticket {
  export interface Encoded extends S.Struct.Encoded<typeof Ticket["fields"]> {}
}
/* eslint-enable */
//
// codegen:end
