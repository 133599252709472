import type {} from "#resources/lib"
import { setupSentry } from "~/utils/observability"
import { defineNuxtPlugin } from "nuxt/app"
import { useRuntimeConfig } from "#imports"

export default defineNuxtPlugin(nuxtApp => {
  const config = useRuntimeConfig()
  const isRemote = config.public.env !== "local-dev"
  // Sentry before observability
  setupSentry(nuxtApp.vueApp, isRemote)
})
