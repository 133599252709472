import {
  AccountsRsc,
  NewsRsc,
  SubscriptionsV2Rsc,
  TickersRsc,
  TickersV3Rsc,
  TicketsRsc,
} from "#resources"
import { ImportRsc, OptimizationRsc } from "#resources/Admin"
import { clientFor } from "~/composables/client"

export const tickersV3Client = clientFor(TickersV3Rsc)

export const accountsClient = clientFor(AccountsRsc)

export const tickersClient = clientFor(TickersRsc)

export const newsClient = clientFor(NewsRsc)

export const importClient = clientFor(ImportRsc)

export const optimizationClient = clientFor(OptimizationRsc)

export const ticketsClient = clientFor(TicketsRsc)

export const subscriptionsV2Client = clientFor(SubscriptionsV2Rsc)
