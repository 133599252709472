import { BigDecimal, S } from "effect-app"
import { NonEmptyString255 } from "effect-app/Schema"

export const PaymentId = NonEmptyString255
export type PaymentId = NonEmptyString255

export const Recurrence = S.Literal("monthly", "annually")
export type Recurrence = S.Schema.Type<typeof Recurrence>

export const Currency = S.Literal("EUR")
export type Currency = typeof Currency.Type

/**
 * A plan is paid when the user has paid for the subscription.
 */
export class PlanStatusPaid extends S.ExtendedTaggedClass<PlanStatusPaid, PlanStatusPaid.Encoded>()("paid", {
  at: S.Date.withDefault,
  subscriptionId: NonEmptyString255,
  amount: S.BigDecimal,
  currency: Currency,
  paymentId: NonEmptyString255
}) {}

/**
 * A plan is suspended when the user has paid for the subscription
 * but suspended future payments.
 * Thus, he is still on his current plan till the `expires` date.
 */
export class PlanStatusSuspended
  extends S.ExtendedTaggedClass<PlanStatusSuspended, PlanStatusSuspended.Encoded>()("suspended", {
    expires: S.Date
  })
{}

/**
 * A plan is pending when the user has attempted to subscribe
 * but we haven't received yet the payment confirmation through the mollie hook.
 */
export class PlanStatusPending
  extends S.ExtendedTaggedClass<PlanStatusPending, PlanStatusPending.Encoded>()("pending", {
    at: S.Date.withDefault
  })
{}

export const PlanStatus = S.TaggedUnion(PlanStatusPaid, PlanStatusSuspended, PlanStatusPending)
export type PlanStatus = S.Schema.Type<typeof PlanStatus>

abstract class PaidPlanFields extends S.Class<PaidPlanFields>()({
  recurrence: Recurrence,
  paymentId: PaymentId,
  createdAt: S.Date.withDefault,
  mollieCustomerId: NonEmptyString255,
  nextPaymentAt: S.Date,
  acceptedPremiumTermsAt: S.Date,
  status: PlanStatus
}) {}

export class FreePlan extends S.ExtendedTaggedClass<FreePlan, FreePlan.Encoded>()("free", {
  mollieCustomerId: S.NullOr(NonEmptyString255).withDefault
}) {
  static readonly maxSubscriptions = 1
  static readonly monthly = BigDecimal.unsafeFromString("0")
  static readonly annually = BigDecimal.unsafeFromString("0")
  readonly maxSubscriptions = FreePlan.maxSubscriptions
  readonly monthly = FreePlan.monthly
  readonly annually = FreePlan.annually
}

export class BasicPlan
  extends S.ExtendedTaggedClass<BasicPlan, BasicPlan.Encoded>()("basic", { ...PaidPlanFields.fields })
{
  static readonly maxSubscriptions = 11
  static readonly monthly = BigDecimal.unsafeFromString("24.99")
  static readonly annually = BigDecimal.unsafeFromString("249.99")
  readonly maxSubscriptions = BasicPlan.maxSubscriptions
  readonly monthly = BasicPlan.monthly
  readonly annually = BasicPlan.annually
}

export class ProPlan extends S.ExtendedTaggedClass<ProPlan, ProPlan.Encoded>()("pro", { ...PaidPlanFields.fields }) {
  static readonly maxSubscriptions = 33
  static readonly monthly = BigDecimal.unsafeFromString("49.99")
  static readonly annually = BigDecimal.unsafeFromString("499.99")
  readonly maxSubscriptions = ProPlan.maxSubscriptions
  readonly monthly = ProPlan.monthly
  readonly annually = ProPlan.annually
}

export class GuruPlan
  extends S.ExtendedTaggedClass<GuruPlan, GuruPlan.Encoded>()("guru", { ...PaidPlanFields.fields })
{
  static readonly maxSubscriptions = 111
  static readonly monthly = BigDecimal.unsafeFromString("99.99")
  static readonly annually = BigDecimal.unsafeFromString("999.99")
  readonly maxSubscriptions = GuruPlan.maxSubscriptions
  readonly monthly = GuruPlan.monthly
  readonly annually = GuruPlan.annually
}

export class TrialPlan extends S.ExtendedTaggedClass<TrialPlan, TrialPlan.Encoded>()("trial", {
  mollieCustomerId: S.NullOr(NonEmptyString255).withDefault,
  expiresAt: S.Date
}) {
  static readonly maxSubscriptions = 111
  static readonly monthly = BigDecimal.unsafeFromString("0")
  static readonly annually = BigDecimal.unsafeFromString("0")
  readonly maxSubscriptions = TrialPlan.maxSubscriptions
  readonly monthly = TrialPlan.monthly
  readonly annually = TrialPlan.annually
}

export class GuestPlan extends S.ExtendedTaggedClass<GuestPlan, GuestPlan.Encoded>()("guest", {
  mollieCustomerId: S.NullOr(NonEmptyString255).withDefault
}) {
  static readonly maxSubscriptions = 111
  static readonly monthly = BigDecimal.unsafeFromString("0")
  static readonly annually = BigDecimal.unsafeFromString("0")
  readonly maxSubscriptions = GuestPlan.maxSubscriptions
  readonly monthly = GuestPlan.monthly
  readonly annually = GuestPlan.annually
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const constructorFunction = <T>(ctor: { new(...args: any[]): T }) => (...args: ConstructorParameters<typeof ctor>) =>
  new ctor(...args)

const createBasic = constructorFunction(BasicPlan)
const createPro = constructorFunction(ProPlan)
const createGuru = constructorFunction(GuruPlan)

export const Plan = Object.assign(S.TaggedUnion(FreePlan, BasicPlan, ProPlan, GuruPlan, TrialPlan, GuestPlan), {
  Paid: Object.assign(S.TaggedUnion(BasicPlan, ProPlan, GuruPlan), {
    create(tag: Plan.Paid["_tag"]): (paidData: PaidPlanFields) => Plan.Paid {
      switch (tag) {
        case "basic":
          return createBasic
        case "pro":
          return createPro
        case "guru":
          return createGuru
      }
    }
  }),
  Selectable: S.TaggedUnion(FreePlan, BasicPlan, ProPlan, GuruPlan)
})
export type Plan = S.Schema.Type<typeof Plan>
export namespace Plan {
  export type Encoded = typeof Plan.Encoded
  export type Paid = S.Schema.Type<typeof Plan.Paid>
  export type Selectable = S.Schema.Type<typeof Plan.Selectable>
}

export const planMap = Plan.tagMap satisfies {
  [K in Plan["_tag"]]: {
    maxSubscriptions: number
    monthly: BigDecimal.BigDecimal
    annually: BigDecimal.BigDecimal
  }
}

export const getPrice = (tag: Plan["_tag"], recurrence: Recurrence) => BigDecimal.format(planMap[tag][recurrence])

// codegen:start {preset: model}
//
/* eslint-disable */
export namespace PlanStatusPaid {
  export interface Encoded extends S.Struct.Encoded<typeof PlanStatusPaid["fields"]> {}
}
export namespace PlanStatusSuspended {
  export interface Encoded extends S.Struct.Encoded<typeof PlanStatusSuspended["fields"]> {}
}
export namespace PlanStatusPending {
  export interface Encoded extends S.Struct.Encoded<typeof PlanStatusPending["fields"]> {}
}
export namespace FreePlan {
  export interface Encoded extends S.Struct.Encoded<typeof FreePlan["fields"]> {}
}
export namespace BasicPlan {
  export interface Encoded extends S.Struct.Encoded<typeof BasicPlan["fields"]> {}
}
export namespace ProPlan {
  export interface Encoded extends S.Struct.Encoded<typeof ProPlan["fields"]> {}
}
export namespace GuruPlan {
  export interface Encoded extends S.Struct.Encoded<typeof GuruPlan["fields"]> {}
}
export namespace TrialPlan {
  export interface Encoded extends S.Struct.Encoded<typeof TrialPlan["fields"]> {}
}
export namespace GuestPlan {
  export interface Encoded extends S.Struct.Encoded<typeof GuestPlan["fields"]> {}
}
/* eslint-enable */
//
// codegen:end
//
