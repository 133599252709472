import { defineNuxtPlugin } from "nuxt/app"
import * as Sentry from "@sentry/vue"

export default defineNuxtPlugin(nuxtApp => {
  // nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
  //   // handle error, e.g. report to a service
  // }

  nuxtApp.hook("vue:error", (error, instance, info) => {
    console.warn("capturing global error", error, instance, info)
    Sentry.captureException(error)
  })
})
