import { News, NewsId, NewsStatus } from "#api/models/News"
import { S } from "#resources/lib"
import { makeExactOptional, makeOptional } from "effect-app/Schema"

export class Index extends S.Req<Index>()("Index", {}, {
  allowedRoles: ["user"],
  success: S.Array(News)
}) {}

export class SearchInput extends S.ExtendedClass<SearchInput, SearchInput.Encoded>()({
  ...makeOptional(News.pick("author", "securities")),
  title: S.String.pipe(S.optional),
  createdAt: S.Date.pipe(S.optional),
  status: S.Literal(...NewsStatus.fields.value.literals, "any")
}) {}

export class Search extends S.Req<Search>()("Search", {
  input: SearchInput
}, {
  allowedRoles: ["user"],
  success: S.Array(News)
}) {}

export class Get extends S.Req<Get>()("Get", {
  id: NewsId
}, {
  allowedRoles: ["user"],
  success: News
}) {}

export class CreateNewsInput extends S.ExtendedClass<CreateNewsInput, CreateNewsInput.Encoded>()({
  ...News.omit("id", "createdAt", "author", "status")
}) {}

export class Create extends S.Req<Create>()("Create", {
  input: CreateNewsInput
}, {
  allowedRoles: ["manager"]
}) {}

export class UpdateNewsInput extends S.ExtendedClass<UpdateNewsInput, UpdateNewsInput.Encoded>()({
  ...makeExactOptional(News.omit("id", "createdAt", "author", "status"))
}) {}

export class Update extends S.Req<Update>()("Update", {
  id: NewsId,
  input: UpdateNewsInput
}, {
  allowedRoles: ["manager"]
}) {}

export class Delete extends S.Req<Delete>()("Delete", {
  id: NewsId
}, {
  allowedRoles: ["manager"]
}) {}

// codegen:start {preset: model}
//
/* eslint-disable */
export namespace SearchInput {
  export interface Encoded extends S.Struct.Encoded<typeof SearchInput["fields"]> {}
}
export namespace CreateNewsInput {
  export interface Encoded extends S.Struct.Encoded<typeof CreateNewsInput["fields"]> {}
}
export namespace UpdateNewsInput {
  export interface Encoded extends S.Struct.Encoded<typeof UpdateNewsInput["fields"]> {}
}
/* eslint-enable */
//
// codegen:end

// codegen:start {preset: meta, sourcePrefix: "src/resources/"}
export const meta = { moduleName: "News" } as const
// codegen:end
