import { AlgorithmSettings, ATRAlgorithmSettings, RollingAlgorithmSettings } from "#models/AlgorithmSettings"
import {
  IterativeOptimizationReified,
  Optimization,
  OptimizationContainer,
  OptimizationId,
  OptimizationWithStatusMono
} from "#models/Optimization"
import { EODData, TickerData, TickerSymbol } from "#models/Security"
import { ATRSignal, PerformanceValue, RollingSignal, SimpleSignal, WMAEMASignal } from "#models/Signals"
import { S } from "#resources/lib"
import { NonEmptyString255, optional } from "effect-app/Schema"

// Combine Algorithms
export class CombineAlgorithmsResponse extends S.Class<CombineAlgorithmsResponse>()({
  ticker: TickerData,
  eodData: S.Array(EODData),
  signals: S.Array(SimpleSignal),
  performanceData: S.Array(PerformanceValue),
  eodsCAGR: S.Number,
  performanceCAGR: S.Number,
  performanceSD: S.Number,
  dateFrom: S.Date
}) {}

export class CombineAlgorithms extends S.Req<CombineAlgorithms>()("CombineAlgorithms", {
  symbol: TickerSymbol,
  entrySettings: AlgorithmSettings,
  exitSettings: S.Union(ATRAlgorithmSettings, RollingAlgorithmSettings),
  eagerBuy: S.Boolean,
  dateFrom: S.Date,
  syntheticBuyOnFirstDay: S.Boolean.pipe(S.withDefaultConstructor(() => false))
}, { allowedRoles: ["manager"], success: CombineAlgorithmsResponse }) {}

// Delete Oprimization
export class DeleteOptimizationResponse extends S.Class<DeleteOptimizationResponse>()({
  deletedOptimization: OptimizationContainer
}) {}

export class DeleteOptimization extends S.Req<DeleteOptimization>()("DeleteOptimization", {
  id: OptimizationId
}, { allowedRoles: ["manager"], success: DeleteOptimizationResponse }) {}

// Execute Algorithm
export class ExecuteAlgorithmResponse extends S.Class<ExecuteAlgorithmResponse>()({
  ticker: TickerData,
  eodData: S.Array(EODData),
  signals: S.Union(S.Array(ATRSignal), S.Array(WMAEMASignal), S.Array(RollingSignal)),
  performanceData: S.Array(PerformanceValue),
  eodsCAGR: S.Number,
  performanceCAGR: S.Number,
  performanceSD: S.Number,
  dateFrom: S.Date
}) {}

export class ExecuteAlgorithm extends S.Req<ExecuteAlgorithm>()("ExecuteAlgorithm", {
  symbol: TickerSymbol,
  settings: AlgorithmSettings,
  dateFrom: S.Date,
  syntheticBuyOnFirstDay: S.Boolean.pipe(S.withDefaultConstructor(() => false))
}, { allowedRoles: ["manager"], success: ExecuteAlgorithmResponse }) {}

// Get Optimization Results
export class GetOptimizationResultsResponse extends S.Class<GetOptimizationResultsResponse>()({
  // batched optimizations are an internal detail
  data: S.Array(S.Union(OptimizationWithStatusMono, IterativeOptimizationReified))
}) {}

export class GetOptimizationResults extends S.Req<GetOptimizationResults>()("GetOptimizationResults", {}, {
  allowedRoles: ["manager"],
  success: GetOptimizationResultsResponse
}) {}

// Optimize Algorithm

export class OptimizeAlgorithm extends S.Req<OptimizeAlgorithm>()("OptimizeAlgorithm", {
  symbols: S.NonEmptyArray(TickerSymbol),
  optimization: Optimization,
  name: optional(NonEmptyString255),
  dateFromTimestamp: S.Number,
  skipInvalidDataCheck: S.Boolean.pipe(S.withDefaultConstructor(() => false)),
  syntheticBuyOnFirstDay: S.Boolean.pipe(S.withDefaultConstructor(() => false))
}, { allowedRoles: ["manager"] }) {}

// Reset Eod Data

// Iterative Optimization
export class IterativelyOptimizeAlgorithm
  extends S.Req<IterativelyOptimizeAlgorithm>()("IterativelyOptimizeAlgorithm", {
    name: optional(NonEmptyString255),
    optimization: Optimization,
    symbols: S.NonEmptyArray(TickerSymbol),
    dateFromTimestamp: S.Number,
    minPerformance: S.PositiveNumber,
    skipInvalidDataCheck: S.Boolean.pipe(S.withDefaultConstructor(() => false)),
    syntheticBuyOnFirstDay: S.Boolean.pipe(S.withDefaultConstructor(() => false))
  }, { allowedRoles: ["manager"] })
{}

// codegen:start {preset: meta, sourcePrefix: "src/resources/"}
export const meta = { moduleName: "Admin/Optimization" } as const
// codegen:end
