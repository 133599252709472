import { Context, Effect, Equivalence, pipe, S } from "effect-app"
import type { NotFoundError } from "effect-app/client"
import { fakerArb } from "effect-app/faker"
import { UserProfileId } from "effect-app/ids"
import type { A, Schema, StringIdBrand } from "effect-app/Schema"
import {
  annotations,
  AST,
  Email,
  NonEmptyString255,
  NonEmptyString2k,
  ParseResult,
  prefixedStringId,
  withDefaultMake
} from "effect-app/Schema"
import type { Simplify } from "effect/Types"
import { FreePlan, Plan } from "./Plan.js"

export const FirstName = NonEmptyString255
  .pipe(
    annotations({
      [AST.ArbitraryAnnotationId]: (): A.LazyArbitrary<string> => fakerArb((faker) => faker.person.firstName)
    }),
    withDefaultMake
  )

export type FirstName = Schema.Type<typeof FirstName>

export const DisplayName = FirstName
export type DisplayName = Schema.Type<typeof DisplayName>

export const LastName = NonEmptyString255
  .pipe(
    annotations({
      [AST.ArbitraryAnnotationId]: (): A.LazyArbitrary<string> => fakerArb((faker) => faker.person.lastName)
    }),
    withDefaultMake
  )

export type LastName = Schema.Type<typeof LastName>

export class FullName extends S.ExtendedClass<FullName, FullName.Encoded>()({
  firstName: FirstName,
  lastName: LastName
}) {
  static render(this: void, fn: FullName) {
    return NonEmptyString2k(`${fn.firstName} ${fn.lastName}`)
  }

  static create(this: void, firstName: FirstName, lastName: LastName) {
    return new FullName({ firstName, lastName })
  }
}
/**
 * A string that is at least 6 characters long and a maximum of 50.
 */

export interface UserIdBrand extends Simplify<S.B.Brand<"UserId"> & StringIdBrand> {}
export const UserId = prefixedStringId<UserId>()("user", "User")

export type UserId = S.StringId & UserIdBrand

export const Role = S.Literal("manager", "user").pipe(withDefaultMake)
export type Role = Schema.Type<typeof Role>

export const Locale = S.Literal("de", "en")
export type Locale = Schema.Type<typeof Locale>

export const EmailFrequency = S.Literal("never", "daily", "signalChange")
export type EmailFrequency = Schema.Type<typeof EmailFrequency>

export class User extends S.ExtendedClass<User, User.Encoded>()({
  id: UserId.withDefault,
  email: Email,
  displayName: DisplayName,
  role: Role,
  auth0Id: S.NullOr(UserProfileId),
  emailFrequency: EmailFrequency.pipe(S.withDefaultConstructor(() => "daily" as EmailFrequency)),
  locale: Locale.pipe(S.withDefaultConstructor(() => "de" as Locale)),
  plan: Plan.pipe(S.withDefaultConstructor(() => new FreePlan())),
  createdAt: S.Date.withDefault,
  lastActivityAt: S.Date.withDefault
}) {
  static readonly resolver = Context.GenericTag<UserFromId, (userId: UserId) => Effect<User, NotFoundError>>(
    "UserFromId"
  )
}

export interface UserFromId {
  readonly _: unique symbol
}

export const UserFromId: Schema<User, string, UserFromId> = S.transformOrFail(
  UserId,
  S.typeSchema(User),
  {
    decode: (id) =>
      User.resolver.pipe(
        Effect.andThen((_) => _(id)),
        Effect.mapError((error) => new S.ParseResult.Unexpected(null, error.message))
      ),
    encode: (u) => ParseResult.try({ try: () => u.id, catch: (e) => new S.ParseResult.Unexpected(null, `${e}`) })
  }
)

export function showUser(user: User) {
  return user.displayName
}

export const defaultEqual = pipe(Equivalence.string, Equivalence.mapInput((u: User) => u.id))

// TODO
// let userPool: readonly User[] | null = null
// export function setUserPool(pool: readonly User[] | null) {
//   userPool = pool
// }

// const User___ = union({ RegisteredUser, Guest, Ghost, Archived })
// const userArb = Arbitrary.for(User___)
// const User_ = enhanceClassUnion(
//   OpaqueSchema<User, User.Encoded>()(User___)
//     ["|>"](arbitrary(_ => (userPool ? _.constantFrom(...userPool) : userArb(_))))
//     ["|>"](withDefaultMake)

// codegen:start {preset: model}
//
/* eslint-disable */
export namespace FullName {
  export interface Encoded extends S.Struct.Encoded<typeof FullName["fields"]> {}
}
export namespace User {
  export interface Encoded extends S.Struct.Encoded<typeof User["fields"]> {}
}
/* eslint-enable */
//
// codegen:end
//
